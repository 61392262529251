<template>
  <a-modal v-model:open="showRenameModal" title="Rename Cycle" centered :footer="null">
    <a-input v-model:value="cycleName" @keyup.enter="handleRenameCycle">
      <template #suffix>
        <a-tooltip title="Rename">
          <EnterOutlined class="cursor-pointer" @click="handleRenameCycle" />
        </a-tooltip>
      </template>
    </a-input>
  </a-modal>

  <div class="main-container">
    <div class="head-container">
      <a-space>
        <a-typography-title :level="4" class="mb-0">Mark Cycles</a-typography-title>
      </a-space>

      <a-space class="d-flex align-items-center">
        <a-button
          :disabled="!isCyclesUpdated || isUpdateCycles"
          class="d-flex align-items-center"
          @click="handleReset()"
        >
          <template #icon>
            <UndoOutlined />
          </template>
          Reset
        </a-button>
        <a-button
          :loading="isUpdateCycles"
          :disabled="isCycleMarking"
          class="d-flex align-items-center"
          @click="handleSaveCycles"
        >
          <CheckOutlined v-if="isCyclesUpdated" />
          <ShrinkOutlined v-else />
          {{ isCyclesUpdated ? 'Save' : 'Exit' }}
        </a-button>
      </a-space>
    </div>
    <div class="video-container-wrapper">
      <!-- Text Section -->
      <div class="instructions">
        <ol style="padding-left: 14px">
          <li v-for="(instruction, index) in markingInstructions" :key="index" class="mb-2">
            {{ instruction }}
          </li>
        </ol>
      </div>
      <div class="video-container">
        <video
          v-if="sourceVideoUrl"
          ref="video"
          :src="sourceVideoUrl"
          muted
          disablePictureInPicture
          @playing="isVideoPlaying = true"
          @loadedmetadata="handleGetDuration"
          @timeupdate="handleGetCurrentTime"
          crossorigin="anonymous"
          preload="metadata"
        ></video>
      </div>
    </div>

    <div class="timeline-container">
      <div class="d-flex justify-content-between align-items-center">
        <!-- start time -->
        <span class="position-relative">
          <small class="me-1">{{ getFormattedTime(currentTime) }}</small>
          <small v-if="isCycleMarking" class="cycleSelectedTxt">
            Start Selected, Adjust Slider and Press SPACE to mark cycle end
          </small>
          <small v-if="editCycle" class="cycleSelectedTxt">
            Cycle Selected, Adjust Slider and Press SPACE to complete editing
          </small>
        </span>

        <div class="play-button-container position-relative">
          <small>
            <PauseOutlined style="font-size: 20px" v-if="isVideoPlaying" @click="togglePlaying" />
            <CaretRightOutlined style="font-size: 20px" v-else @click="togglePlaying" />
          </small>
        </div>
        <small> Playback speed: </small>
        <div class="position-relative">
          <a-select
            v-model:value="playbackRate"
            class="me-2 d-flex align-items-center p-1 pt-1"
            size="small"
            :options="playbackRatesOptions"
            @change="handlePlaybackRateChange"
          />
        </div>
        <!-- end time -->
        <small>{{ getFormattedTime(videoDuration) }}</small>
      </div>

      <a-slider
        v-model:value="currentFrame"
        :min="0"
        :max="totalFrames"
        :tipFormatter="(val) => formatFrame(val)"
        id="annotate-slider"
        class="mx-0 mt-1"
        @change="handleVideoPause"
      />

      <div
        :style="{ height: '20px', width: '100%', position: 'relative' }"
        class="m-0 border d-flex"
      >
        <!-- vertical bar to represent current time -->
        <div class="hairline" :style="cursorStyle">
          <div class="hairline-top"></div>
        </div>

        <!-- Cycles Annotations -->
        <painter
          v-for="(interval, index) in cycles"
          :key="index"
          :isMarking="isCycleMarking || editCycle"
          :contextMenuItems="getContextMenuItems(interval)"
          :interval="interval"
          :intervalIndex="index"
          :current-slider-percent="currentSliderPercent"
          :customStyles="getCustomStyles(interval)"
          :prevEnd="getPrevEnd(index)"
          @editInterval="handleEditCycle"
          @removeInterval="handleRemoveCycle"
          @renameInterval="handleShowRenameModal"
        >
          <template #intervalName>
            <span>{{ interval.name }}</span>
          </template>
        </painter>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PauseOutlined,
  CaretRightOutlined,
  UndoOutlined,
  CheckOutlined,
  ShrinkOutlined,
  EnterOutlined
} from '@ant-design/icons-vue'
import { cyclesMenuItem, masterCyclesMenuItem, markingInstructions } from '../config'
import { getFormattedTime } from 'src/utils/outline'
import { mapActions, mapState } from 'pinia'
import { useStationStore } from 'src/stores/station'
import { useToast } from 'vue-toastification'
import { toLower } from 'src/utils/helpers'
import Painter from './Painter.vue'

const toast = useToast()

export default {
  props: ['selectedFileId'],
  emits: ['close'],
  components: {
    PauseOutlined,
    CaretRightOutlined,
    UndoOutlined,
    CheckOutlined,
    ShrinkOutlined,
    EnterOutlined,
    Painter
  },
  setup: () => ({
    cyclesMenuItem,
    masterCyclesMenuItem,
    markingInstructions,
    getFormattedTime
  }),
  data() {
    return {
      // video
      cycleCount: 0,
      playbackRate: 1,
      sourceVideoUrl: null,
      videoDuration: 0,
      currentTime: 0,
      isVideoPlaying: false,
      isVideoPaused: false,
      isVideoLoaded: false,
      // cycles
      cycles: [],
      cycleIndex: null,
      existingCyclesCount: 0,
      isCycleMarking: false,
      isUpdateCycles: false,
      deleteCyclesIds: new Set(),
      prevCycleIdToObjMap: null,
      selectedCycle: null,
      showRenameModal: false,
      cycleName: null,
      // slider
      currentFrame: 0,
      currentSliderPercent: 0,
      startingTime: null,
      editCycle: false,
      editPosition: null,
      fileCycles: []
    }
  },
  computed: {
    ...mapState(useStationStore, ['newStudy', 'workCycles', 'studyFilesObject']),
    playbackRatesOptions() {
      return [1, 2, 4, 8, 16].map((rate) => ({
        value: rate,
        label: `${rate}x`
      }))
    },
    videoFPS() {
      const file = this.studyFilesObject[this.selectedFileId]
      return file ? file.fps : 30
    },

    totalFrames() {
      return this.videoDuration ? this.videoFPS * this.videoDuration : 0
    },

    cursorStyle() {
      return {
        left: this.currentSliderPercent + '%'
      }
    },

    isCyclesUpdated() {
      return (
        this.cycles?.length !== this.fileCycles?.length ||
        this.updatedCycles?.length ||
        [...this.deleteCyclesIds]?.length
      )
    },

    updatedCycles() {
      if (!this.cycles || !this.cycles?.length || !this.prevCycleIdToObjMap) return []
      const updatedCycles = this.cycles?.filter((cycle) => {
        const oldCycleObj = this.prevCycleIdToObjMap[cycle.id]
        if (oldCycleObj) return JSON.stringify(cycle) !== JSON.stringify(oldCycleObj)
        else return false
      })
      return updatedCycles
    },

    isCyclesCountUpdated() {
      if (!this.prevCycleIdToObjMap || !Object.keys(this.prevCycleIdToObjMap)?.length) return true
      const prevCycles = Object.keys(this.prevCycleIdToObjMap)
      const currCycles = this.cycles.filter((c) => c.segment_start !== c.segment_end)
      return prevCycles.length !== currCycles.length
    }
  },

  watch: {
    playbackRate() {
      this.handlePlaybackRateChange()
    },
    workCycles(value) {
      if (!value.length) return
      this.fileCycles = this.workCycles.filter((c) => c.file === this.selectedFileId)
      this.setExistingCyclesIntervals()
      this.setCycleIdToObjectMap()
    },

    totalFrames(val) {
      if (!val) return
      if (!this.fileCycles.length)
        this.fileCycles = this.workCycles.filter((c) => c.file === this.selectedFileId)
      this.existingCyclesCount = this.workCycles.length
      this.setExistingCyclesIntervals()
      this.setCycleIdToObjectMap()
    },

    currentFrame(frame) {
      const video = this.$refs.video
      if (video) {
        this.currentTime = frame / this.videoFPS
        if (Number(video.currentTime.toFixed(2)) != Number(this.currentTime.toFixed(2))) {
          video.currentTime = this.currentTime
        }
      }
      this.currentSliderPercent = this.getPercent(frame)
      this.editCycle ? this.editCyclePaint() : this.handlePainterChange(frame)
    }
  },
  methods: {
    ...mapActions(useStationStore, [
      'fetchWorkCyles',
      'addWorkCycles',
      'updateWorkCycle',
      'deleteWorkCycle',
      'updateStation',
      'updateStudy'
    ]),

    getCustomStyles(interval) {
      if (this.newStudy.status === 'Completed' && interval.is_master) {
        return {
          backgroundColor: '#3192b5'
        }
      }
      return {}
    },

    getContextMenuItems(interval) {
      if (this.newStudy.status === 'Completed' && interval.is_master) {
        return this.masterCyclesMenuItem
      }
      return this.cyclesMenuItem
    },

    handlePlaybackRateChange() {
      if (this.$refs.video) {
        this.$refs.video.playbackRate = this.playbackRate
      }
    },

    formatFrame(frame) {
      return `Frame id: ${frame}`
    },

    getFrame(percent) {
      if (percent === undefined) return 0
      return Math.ceil((percent * this.totalFrames) / 100)
    },

    getPercent(frame) {
      return (frame / this.totalFrames) * 100
    },

    handleGetDuration() {
      this.videoDuration = parseInt(this.$refs.video?.duration, 10)
      this.isVideoLoaded = true
    },

    isOverlapped(currentStart) {
      return this.cycles.some(
        ({ segment_start: start, segment_end: end }) => start <= currentStart && currentStart <= end
      )
    },

    getPrevEnd(index) {
      let idx = index - 1 < 0 ? 0 : index - 1
      return this.cycles[idx].segment_end
    },

    setCycleIdToObjectMap() {
      if (!this.fileCycles?.length) return
      this.prevCycleIdToObjMap = this.fileCycles.reduce((res, el) => {
        res[el.id] = {
          ...el,
          segment_start: this.getPercent(el['segment_start'] * this.videoFPS),
          segment_end: this.getPercent(el['segment_end'] * this.videoFPS)
        }
        return res
      }, {})
    },

    // Video Play & Pause
    togglePlaying() {
      if (!this.isVideoLoaded) return

      if (!this.isVideoPlaying && this.$refs.video?.paused) {
        this.handleVideoPlay()
      } else {
        this.handleVideoPause()
      }
    },

    handleGetCurrentTime(event) {
      this.currentFrame = event.target.currentTime * this.videoFPS
    },

    async handleVideoPlay() {
      if (this.isVideoLoaded) await this.$refs.video?.play()
    },

    handleVideoPause() {
      if (!this.$refs.video) return
      if (!this.$refs.video?.paused && this.isVideoPlaying) this.$refs.video?.pause()
      this.isVideoPaused = true
      this.isVideoPlaying = false
    },

    getFileName(fileName) {
      if (!fileName) return ''
      const chars = fileName.split('.')
      return chars.length > 1 ? chars.slice(0, -1).join('.') : fileName
    },

    // API CALLS
    getNewCycles() {
      let newCycles = {}
      this.cycles.forEach((cycle, i) => {
        if (!cycle.id) {
          newCycles[i] = {
            interval: [this.getFrame(cycle.segment_start), this.getFrame(cycle.segment_end)],
            name: cycle.name
          }
        }
      })
      return !Object.keys(newCycles)?.length ? null : newCycles
    },

    getNextCyclesPayload(startIndex, array, newCyclesMap, obj = {}) {
      const nextCycles = array.slice(startIndex + 1, array.length)
      nextCycles.forEach((n) => {
        if (n.id && newCyclesMap[n.id]) {
          obj[n.id] = {
            ...obj[n.id],
            cycle_no: this.cycles.findIndex((c) => c.id === n.id)
          }
        }
      })
      return obj
    },

    getUpdatedWorkCycles() {
      let cyclesIdToPayload = {}
      let isInsert = false
      if (!this.prevCycleIdToObjMap || !Object.keys(this.prevCycleIdToObjMap)?.length)
        return { isInsert, cyclesIdToPayload }

      const prevCycles = Object.values(this.prevCycleIdToObjMap)
      const newCyclesMap = this.cycles.reduce((res, el) => {
        if (el.id) res[el.id] = el
        return res
      }, {})

      // for delete / update prev cycles
      prevCycles.forEach((cycle, i, arr) => {
        const newCycleObj = newCyclesMap[cycle.id]
        if (this.deleteCyclesIds.has(cycle.id)) {
          const nextCyclesObjs = this.getNextCyclesPayload(i, arr, newCyclesMap, cyclesIdToPayload)
          cyclesIdToPayload = { ...cyclesIdToPayload, ...nextCyclesObjs }
        } else if (newCycleObj && JSON.stringify(cycle) !== JSON.stringify(newCycleObj)) {
          cyclesIdToPayload[cycle.id] = { ...cyclesIdToPayload[cycle.id] }
          // if start/end update
          if (
            cycle.segment_start !== newCycleObj.segment_start ||
            cycle.segment_end !== newCycleObj.segment_end
          ) {
            cyclesIdToPayload[cycle.id] = {
              ...cyclesIdToPayload[cycle.id],
              cycle_start_frame_no: this.getFrame(newCycleObj.segment_start),
              cycle_end_frame_no: this.getFrame(newCycleObj.segment_end)
            }
          }
          // if name update
          if (cycle.name !== newCycleObj.name) {
            cyclesIdToPayload[cycle.id]['name'] = newCycleObj.name
          }
        }
      })
      // for insert between 2 cycles
      this.cycles.forEach((c, i, arr) => {
        if (!c.id) {
          isInsert = true
          const nextCyclesObjs = this.getNextCyclesPayload(i, arr, newCyclesMap, cyclesIdToPayload)
          cyclesIdToPayload = { ...cyclesIdToPayload, ...nextCyclesObjs }
        }
      })
      return { isInsert, cyclesIdToPayload: cyclesIdToPayload }
    },

    async handleUpdateCycles(updatePayload) {
      const { isInsert, cyclesIdToPayload: updatedCycles } = updatePayload
      if (!Object.keys(updatedCycles)?.length) return false
      let sortedArr = []
      const [cycleIndexUpdates, cycleTimingUpdate] = Object.entries(updatedCycles).reduce(
        (res, el) => {
          if (Object.prototype.hasOwnProperty.call(el[1], 'cycle_no')) res[0].push(el)
          else res[1].push(el)
          return res
        },
        [[], []]
      )

      sortedArr = cycleIndexUpdates.sort((a, b) => a[1].cycle_no - b[1].cycle_no)
      if (isInsert) sortedArr = cycleIndexUpdates.sort((a, b) => b[1].cycle_no - a[1].cycle_no)

      for (const id in sortedArr) {
        const key = sortedArr[id][0]
        await this.updateWorkCycle(key, updatedCycles[key])
      }

      for (const id in Object.fromEntries(cycleTimingUpdate)) {
        await this.updateWorkCycle(id, updatedCycles[id])
      }
    },

    async handleSaveCycles() {
      if (this.cycles.some((c) => [null, ''].includes(c.name))) {
        toast.info('Every cycle should have a name assigned!')
        return
      }

      if (!this.isUniqueCycleName()) {
        toast.info('Cycle names should be unique across all video files!')
        return
      }

      if (this.validCycleDuration(true)) {
        toast.info('Cycle interval should not be less than 1 second!')
        return
      }

      const removedCycles = [...this.deleteCyclesIds]
      if (!this.cycles.length && !removedCycles.length) {
        this.$emit('close', false)
        return
      }

      if (!this.isCyclesUpdated) {
        this.$emit('close', this.isCyclesCountUpdated)
        return
      }
      this.isUpdateCycles = true

      // Delete work Cycles
      if (removedCycles?.length) {
        await Promise.all(
          removedCycles.map(async (cycleId) => {
            return await this.deleteWorkCycle(cycleId)
          })
        )
      }
      // Update
      const updatePayload = this.getUpdatedWorkCycles()
      const updatedObjects = updatePayload.cyclesIdToPayload
      const isUpdateCycleExist = Object.keys(updatedObjects)?.length
      const onlyNameUpdates = isUpdateCycleExist
        ? Object.values(updatedObjects).every((obj) => {
            const keys = Object.keys(obj)
            return keys.length === 1 && keys[0] === 'name'
          })
        : false

      console.log('update payload: ', updatePayload, onlyNameUpdates)
      if (isUpdateCycleExist) await this.handleUpdateCycles(updatePayload)
      // Add
      const newCycles = this.getNewCycles()
      if (newCycles) {
        await this.addWorkCycles({ cycles: newCycles, file_id: this.selectedFileId })
      }
      // Update staion work cycles
      if (this.isCyclesCountUpdated) {
        let count = this.cycles.filter((c) => c.segment_start !== c.segment_end)?.length
        let prevCount = this.prevCycleIdToObjMap ? Object.keys(this.prevCycleIdToObjMap).length : 0
        prevCount = this.existingCyclesCount - prevCount
        count = prevCount + count
        await this.updateStation(this.newStudy?.station.id, { no_of_work_cycles: count }, false)
      }

      if (
        this.newStudy.station.no_of_steps &&
        !this.newStudy.progress &&
        !onlyNameUpdates &&
        (removedCycles.length ||
          isUpdateCycleExist ||
          newCycles.length ||
          this.isCyclesCountUpdated)
      ) {
        this.updateStudy({ progress: 1 })
      }
      // Get list
      // await this.fetchWorkCyles()
      // this.setCycleIdToObjectMap()
      this.deleteCyclesIds = new Set()
      this.isUpdateCycles = false
      this.$refs.video.currentTime = 0
      this.$emit('close', this.isCyclesCountUpdated)
      return
    },

    isUniqueCycleName() {
      const otherCycles = this.workCycles.filter((cycle) => cycle.file !== this.selectedFileId)
      const otherCyclesName = [
        ...new Set(otherCycles.map((cycle) => cycle.name && toLower(cycle.name)))
      ]
      const uniqueCurrCycles = new Set(
        this.cycles.map((c) => c.name).filter((name) => ![null, ''].includes(name))
      )
      const isUnique =
        [...uniqueCurrCycles].length === this.cycles.length &&
        !this.cycles.some((c) => c.name && otherCyclesName.includes(toLower(c.name)))
      return isUnique
    },

    setExistingCyclesIntervals() {
      let defaultIntervals = []
      if (!this.totalFrames) return // for getting percent value
      this.fileCycles.forEach((cycle) => {
        const start = this.getPercent(cycle['segment_start'] * this.videoFPS)
        const end = this.getPercent(cycle['segment_end'] * this.videoFPS)
        defaultIntervals.push({ ...cycle, segment_start: start, segment_end: end })
      })
      this.cycles = defaultIntervals.sort((a, b) => a.segment_start - b.segment_start)
      this.cycleCount = this.cycles.length
    },

    handleReset() {
      if (this.$refs.video) this.$refs.video.currentTime = 0
      this.currentTime = 0
      this.currentFrame = 0
      this.deleteCyclesIds = new Set()
      this.setExistingCyclesIntervals()
      this.setCycleIdToObjectMap()
      this.selectedPoint = null
      this.isCycleMarking = false
      this.editCycle = false
      this.startingTime = null
      this.cycleIndex = null
    },

    // Slider /  Cycle Marking
    handleKeyDownEvents(evt) {
      if (!this.isVideoLoaded) return
      if (this.showRenameModal) return
      if (evt.code === 'Space') {
        evt.preventDefault()
        if (this.editCycle) {
          if (!this.validCycleDuration()) return
          this.resetEditCycle()
        } else if (this.isCycleMarking) {
          this.handleMarkCycleEnd()
        } else if (!this.isCycleMarking) {
          this.handleVideoPause()
          this.handleMarkCycleStart()
        }
      }
    },

    setCycleIndexToMark(currentSliderPercent) {
      const cycles = this.cycles.sort((a, b) => a.segment_start - b.segment_start)
      for (let idx = 0; idx < cycles.length; idx++) {
        const { segment_start } = cycles[idx]
        if (currentSliderPercent < segment_start) {
          this.cycleIndex = idx
          return
        }
      }
      this.cycleIndex = this.cycles.length
    },

    getCyclesData(neighbour = 'next') {
      const temp = [...this.cycles]
      const index = this.cycleIndex
      const currentCycle = temp[index]
      let neighbourCycle = null
      if (neighbour === 'next') neighbourCycle = temp[index + 1]
      else if (neighbour === 'previous') neighbourCycle = temp[index - 1]

      return {
        temp,
        index,
        currentCycle,
        neighbourCycle
      }
    },

    validCycleDuration(multiple = false) {
      const intv = [...this.cycles]
      if (multiple) {
        let isValid = false
        isValid = intv.some((c) => {
          const start = this.getFrame(c.segment_start)
          const end = this.getFrame(c.segment_end)
          return end - start > 0 && end - start <= this.videoFPS
        })
        return isValid
      }
      const start = this.getFrame(intv[this.cycleIndex].segment_start)
      const end = this.getFrame(intv[this.cycleIndex].segment_end)
      if (end - start > 0 && end - start <= this.videoFPS) {
        toast.info('Cycle interval should not be less than 1 second!')
        return false
      }
      return true
    },

    getNextCycleName() {
      const cycleRegex = /^Cycle (\d+)$/
      const otherCycles = this.workCycles.filter((c) => c.file !== this.selectedFileId)
      const cycles = [...otherCycles, ...this.cycles]
      const cycleNumbers = cycles
        ?.map((cycle) => {
          const match = cycle.name.match(cycleRegex)
          return match ? parseInt(match[1], 10) : null
        })
        ?.filter((n) => n !== null)
      const largestN = cycleNumbers.length > 0 ? Math.max(...cycleNumbers) : 0
      return `Cycle ${largestN + 1}`
    },

    handleMarkCycleStart() {
      this.startingTime = this.currentFrame
      const currentSliderPercent = this.getPercent(this.currentFrame)
      if (this.isOverlapped(currentSliderPercent)) {
        toast.error('Cycles cannot be overlapped!')
        return
      }
      this.isCycleMarking = true
      this.setCycleIndexToMark(currentSliderPercent)
      const obj = {
        segment_start: currentSliderPercent,
        segment_end: currentSliderPercent,
        name: this.getNextCycleName()
      }
      let temp = [...this.cycles, obj].sort((a, b) => a.segment_start - b.segment_start)
      this.cycles = temp
    },

    handlePainterChange(currentSliderValue) {
      if (!this.isCycleMarking) return
      if (currentSliderValue <= this.startingTime && this.cycles.length > 0) return
      const end = this.getPercent(currentSliderValue)
      const temp = [...this.cycles]
      const nextCycleStart = temp[this.cycleIndex + 1]?.segment_start
      if (nextCycleStart && end >= nextCycleStart) return
      temp[this.cycleIndex].segment_end = end
      this.cycles = temp
    },

    handleMarkCycleEnd() {
      if (!this.isCycleMarking) return

      const intv = [...this.cycles]
      const start = this.getFrame(intv[this.cycleIndex]?.segment_start)
      const end = this.getFrame(intv[this.cycleIndex]?.segment_end)
      if (end === start) this.cycles.splice(this.cycleIndex, 1)

      if (end - start > 0 && end - start <= this.videoFPS) {
        return toast.info('Cycle interval should not be less than 1 second!')
      }

      this.cycleIndex = null
      this.startingTime = null
      this.isCycleMarking = false
    },

    handleRemoveCycle(cycleIndex) {
      const cycle = this.cycles[cycleIndex]
      if (cycle.id && cycle.is_master) {
        return toast.info('Can not delete Master cycle!')
      }
      const cycleId = cycle.id
      if (cycleId) this.deleteCyclesIds.add(cycleId)
      this.cycles.splice(cycleIndex, 1)
      this.cycleIndex = null
      this.startingTime = null
      this.isCycleMarking = false
      this.editCycle = false
      this.editPosition = null
      if (!this.cycles.length) {
        this.currentFrame = 0
      }
    },

    handleEditCycle(editValue, editCycleIndex, position) {
      if (this.editCycle) this.resetEditCycle()
      this.editCycle = true
      this.cycleIndex = editCycleIndex
      this.currentFrame = this.getFrame(editValue)
      this.editPosition = position
      this.handleVideoPause()
    },

    resetEditCycle() {
      this.handleVideoPause()
      this.editCycle = false
      this.cycleIndex = null
      this.editPosition = null
    },

    editCyclePaint() {
      if (this.cycleIndex < 0) return
      this.editPosition === 'start'
        ? this.editCycleStart()
        : this.editPosition === 'end' && this.editCycleEnd()
    },

    editCycleStart() {
      const { temp, currentCycle, neighbourCycle: prevCycle } = this.getCyclesData('previous')

      const newStart = this.getPercent(this.currentFrame)
      // return if start is greater then end
      if (currentCycle && currentCycle.segment_end < newStart) return
      // return if start is merged with the previous label start
      if ((prevCycle && newStart <= prevCycle.segment_end) || !currentCycle) {
        return toast.info('Two cycles can not be merged!')
      }
      temp[this.cycleIndex].segment_start = newStart
      this.cycles = temp
    },

    editCycleEnd() {
      const { temp, currentCycle, neighbourCycle: nextCycle } = this.getCyclesData('next')

      const newEnd = this.getPercent(this.currentFrame)
      // return if end is greater then start
      if (currentCycle && currentCycle.segment_start > newEnd) return
      // return if end is merged with the next label start
      if ((nextCycle && newEnd >= nextCycle.segment_start) || !currentCycle) {
        return toast.info('Two cycles can not be merged!')
      }
      temp[this.cycleIndex].segment_end = newEnd
      this.cycles = temp
    },

    handleShowRenameModal(cycleIndex) {
      this.selectedCycle = cycleIndex
      this.cycleName = this.cycles[cycleIndex]?.name ? this.cycles[cycleIndex].name : `Cycle Name`
      this.showRenameModal = true
    },

    async handleRenameCycle() {
      const temp = [...this.cycles]
      const index = temp.findIndex((s, i) => i === this.selectedCycle)

      if (index < 0 || !this.cycleName) return
      const newCycleName = toLower(this.cycleName)
      if (
        this.workCycles.some((c) => toLower(c.name) === newCycleName) ||
        this.cycles.some((c, idx) => idx !== index && toLower(c.name) === newCycleName)
      ) {
        return toast.info('Cycle names should be unique across all video files!')
      }

      temp[index] = {
        ...temp[index],
        name: this.cycleName
      }
      this.cycles = temp
      this.cycleName = ''
      this.showRenameModal = false
    }
  },

  created() {
    this.sourceVideoUrl = this.studyFilesObject[this.selectedFileId].url
  },

  mounted() {
    document.addEventListener('keydown', this.handleKeyDownEvents)
    this.handlePlaybackRateChange()
  },

  unmounted() {
    this.currentTime = 0
    this.prevCycleIdToObjMap = {}
    document.removeEventListener('keydown', this.handleKeyDownEvents)
  }
}
</script>
<style scoped>
.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 0.7em;
}

.head-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-container-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.instructions {
  width: 300px;
  padding-right: 30px;
}

.video-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.video-container video {
  margin: 0 auto;
  height: 100%;
  position: absolute;
}

.control-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0.7em;
}

.timeline-container {
  height: 90px;
  border: 1px solid lightgray;
  padding: 2px 12px;
  position: relative;
  overflow: hidden;
}

.hairline {
  height: 100%;
  width: 1px;
  background: black;
  position: relative;
  z-index: 1;
}

.hairline-top {
  width: 10px;
  height: 10px;
  position: absolute;
  transform: translate(-45%, 0%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid black;
}

.timeline {
  height: 3px;
  width: 100%;
  background: lightgray;
  position: relative;
}

.cycle-timeline {
  height: 3px;
  position: absolute;
  z-index: 2;
}

.selector {
  position: absolute;
  z-index: 2;
  height: 100%;
  /* border: 1px solid gray; */
  border-radius: 5px;
  /* left: 1.5em;
    width: 90%; */
}

.selector-line {
  height: 4px;
  /* background: rgb(24, 144, 255); */
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}
.play-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.cycleSelectedTxt {
  color: #dc3545;
  margin: 0;
  position: absolute;
  left: 100%;
  white-space: nowrap;
  margin-left: 10px;
  /* position: absolute; */
  top: 10%;
}
</style>
