import ApiHelper from './index'

const createNewLine = async (payload) => {
  return await ApiHelper.post('line_balancing/study/', payload)
}

const getLines = async (params) => {
  return await ApiHelper.get('line_balancing/study/', params)
}

const getLine = async (id) => {
  return await ApiHelper.get(`line_balancing/line/${id}/`)
}

const getParentLine = async (id) => {
  return await ApiHelper.get(`line_balancing/study/${id}/get_parent_line/`)
}

const updateLineStudy = async (id, payload) => {
  return await ApiHelper.patch(`line_balancing/study/${id}/`, payload)
}

const deleteLine = async (id) => {
  return await ApiHelper.remove(`line_balancing/study/${id}/`)
}

const createNewStation = async (id, payload) => {
  return await ApiHelper.post(`line_balancing/line/${id}/stations/`, payload)
}

const getAllStations = async (id) => {
  return await ApiHelper.get(`line_balancing/line/${id}/stations/`)
}

const getAllAIStations = async () => {
  return await ApiHelper.get(`line_balancing/station/v1/ai_stations/`) 
}

const updateStation = async (id, payload) => {
  return await ApiHelper.patch(`line_balancing/station/${id}/`, payload)
}

const deleteStation = async (id, params) => {
  return await ApiHelper.remove(`line_balancing/station/delete/${id}/`, params)
}

const createSteps = async (id, payload) => {
  return await ApiHelper.post(`line_balancing/station/${id}/steps/`, payload)
}

const getActionList = async (id) => {
  return await ApiHelper.get(`line_balancing/station/${id}/steps/`)
}

const getWorkCycle = async (id) => {
  return await ApiHelper.get(`line_balancing/station/${id}/workcycle/`)
}

const getAllSteps = async (id) => {
  return await ApiHelper.get(`line_balancing/line/${id}/list_steps/`)
}

const updateSteps = async (id, payload) => {
  return await ApiHelper.patch(`line_balancing/step/${id}/`, payload)
}

const getPredecessors = async (id) => {
  return await ApiHelper.get(`line_balancing/predecessor/${id}/`)
}

const addStepPredecessors = async (payload) => {
  return await ApiHelper.patch('line_balancing/step/add_predecessors/', payload)
}

const balanceLine = async (id, queryParams = {}) => {
  return await ApiHelper.get(`/line_balancing/line/${id}/balance/`, queryParams)
}

const getBalanceRecommendations = async (id, queryParams = {}) => {
  return await ApiHelper.get(`line_balancing/line/${id}/balance_recommendations/`, queryParams)
}

const saveLineBalance = async (id, payload = {}) => {
  return await ApiHelper.post(`/line_balancing/line/${id}/save_line/`, payload)
}

const getAllSaveLines = async (id, params = {}) => {
  return await ApiHelper.get(`/line_balancing/line/${id}/save_line/`, params)
}

const appendSteps = async (id, payload) => {
  return await ApiHelper.post(`line_balancing/station/${id}/steps/?mode=append`, payload)
}

const updateStationOrder = async (id, payload) => {
  return await ApiHelper.patch(`line_balancing/line/${id}/station_order/`, payload)
}

const cloneLineStudy = async (lineId) => {
  return await ApiHelper.post(`line_balancing/line/${lineId}/copy/`)
}

const updateStepsOrder = async (payload) => {
  return await ApiHelper.patch(`line_balancing/step/bulk_update_order/`, payload)
}

export default {
  createNewLine,
  getLines,
  getLine,
  getParentLine,
  updateLineStudy,
  deleteLine,
  createNewStation,
  getAllStations,
  getAllAIStations,
  updateStation,
  deleteStation,
  createSteps,
  getActionList,
  getWorkCycle,
  getAllSteps,
  updateSteps,
  getPredecessors,
  balanceLine,
  getBalanceRecommendations,
  saveLineBalance,
  getAllSaveLines,
  appendSteps,
  addStepPredecessors,
  updateStationOrder,
  cloneLineStudy,
  updateStepsOrder
}
